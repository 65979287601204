import PropTypes from 'prop-types';
import React, { useCallback, useContext, useEffect } from 'react';

import { Box, Typography } from '@material-ui/core';

import { LeasingCalcContext } from '../../LeasingCalcContext';
import Styled from './LeasingCalculatorTab.styled';
import SliderInput from './SliderInput';
import { useLeasingCalculate } from './useLeasingCalculate';

const LeasingCalcBox = ({ onCalc, rate }) => {
  const { calcData, setCalcData } = useContext(LeasingCalcContext);

  const handleStateChange = useCallback((field, value) => {
    setCalcData({ ...calcData, [field]: value });
  }, [calcData, setCalcData]);

  const calcRate = rate ? rate : 0.01;

  const calcResult = useLeasingCalculate({ ...calcData, rate: calcRate });

  useEffect(() => {
    onCalc({ amount: calcData.amount, ...calcResult })
  }, [calcData, calcResult, onCalc]);

  return (
    <Box sx={Styled.CalcBox}>
      <Box sx={{ mb: 7 }}>
        <Typography sx={{ fontSize: 22, fontWeight: 700 }}>
          Стоимость оборудования
        </Typography>
        <Box sx={{ position: 'relative' }}>
          <SliderInput
            marks={[
              { value: 1E+5, label: '100 000 руб' },
              { value: 2e+7, label: '20 000 000 руб' },
            ]}
            mask="num руб"
            max={2e+7}
            min={1E+5}
            name="amount"
            onChange={handleStateChange}
            step={1E+4}
            value={calcData.amount}
          />
        </Box>
      </Box>
      <Box sx={{ mb: 7 }}>
        <Typography sx={{ fontSize: 22, fontWeight: 700 }}>
          Срок договора
        </Typography>
        <Box sx={{ position: 'relative' }}>
          <SliderInput
            marks={[
              { value: 12, label: '12 мес' },
              { value: 48, label: '48 мес' },
            ]}
            mask="num мес"
            max={48}
            min={12}
            name="contractPeriod"
            onChange={handleStateChange}
            step={1}
            value={calcData.contractPeriod}
          />
        </Box>
      </Box>
      <Box>
        <Typography sx={{ fontSize: 22, fontWeight: 700 }}>
          Авансовый платеж
        </Typography>
        <Box sx={{ position: 'relative' }}>
          <SliderInput
            marks={[
              { value: 0, label: '0%' },
              { value: 49, label: '49%' },
            ]}
            mask="num%"
            max={49}
            min={0}
            name="advancePayment"
            onChange={handleStateChange}
            step={1}
            value={calcData.advancePayment}
          />
        </Box>
      </Box>
    </Box>
  );
};

LeasingCalcBox.propTypes = {
  onCalc: PropTypes.func.isRequired,
  rate: PropTypes.number.isRequired
};

export default LeasingCalcBox;