import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';

import { Box, Typography } from '@material-ui/core';

import { LeasingCalcProvider } from "../../LeasingCalcContext";
import LeasingCalcBox from './LeasingCalcBox';
import LeasingCalcDataBox from './LeasingCalcDataBox';
import Styled from './LeasingCalculatorTab.styled';

LeasingCalculatorTab.propTypes = {
  onSwitchToTariffs: PropTypes.func,
  rate: PropTypes.number
}

export default function LeasingCalculatorTab({ onSwitchToTariffs, rate }) {
  const [calcResult, setCalcResult] = useState({
    fullAmount: 0,
    monthlyPayment: 0,
    savingAmount: 0,
  });

  const handleCalcResult = useCallback(
    (result) => setCalcResult(result),
    [setCalcResult],
  );

  return (
    <LeasingCalcProvider>
      <Box>
        <Typography
          sx={{ mb: { xs: 4, md: 5, lg: 6, textAlign: 'center' } }}
          variant="h2"
        >
          Калькулятор лизинга
        </Typography>
        <Typography sx={{ fontSize: 18 }} variant="body1">
          Мы даем оборудование в лизинг любому бизнесу: от небольших{' '}
          предприятий пищевой промышленности до крупных металлообрабатывающих{' '}
          заводов. Предлагаем большой выбор нового и современного оборудования{' '}
          отечественного и иностранного производства для лизинга и последующей покупки.
        </Typography>
        <Box sx={Styled.Container}>
          <LeasingCalcBox onCalc={handleCalcResult} rate={rate} />
          <LeasingCalcDataBox {...calcResult} onSwitchToTariffs={onSwitchToTariffs} />
        </Box>
      </Box>
    </LeasingCalcProvider>
  );
}